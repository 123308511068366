import React from 'react';
import styled from 'styled-components';

import PortableText from '@sanity/block-content-to-react';
import Flickity from 'react-flickity-component';
import Grid from '../Grid';

const flickityOptions = {
  initialIndex: 2,
  prevNextButtons: false,
  pageDots: false,
  wrapAround: true,
  autoPlay: 1500,
};

const TitleStyles = styled.div`
  grid-column: 1 / span 12;
  @media screen and (min-width: 64em) {
    grid-column: 1 / span 8;
  }
  margin-bottom: 0px;
`;

const CarouselCell = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  min-height: 30px;
  min-height: 60px;
  img {
    max-width: 70px;
  }
  width: 33%;
  @media screen and (min-width: 64em) {
    min-height: 90px;
    width: 16%;
  }
`;

export default function LogoSlider({ subtitle, title, richText, images }) {
  return (
    <>
      <div className="container no-margin-bottom">
        <Grid>
          <TitleStyles>
            {subtitle ? <h6>{subtitle}</h6> : null}
            {title ? <h2>{title}</h2> : null}
            <PortableText blocks={richText} />
          </TitleStyles>
        </Grid>
      </div>
      <Flickity
        className="carousel" // default ''
        elementType="div" // default 'div'
        style={{ outline: 'none' }}
        options={flickityOptions} // takes flickity options {}
        disableImagesLoaded={false} // default false
        reloadOnUpdate // default false
        static // default false
      >
        {images.map((image, i) => (
          <CarouselCell key={i}>
            <img src={image.asset.url} alt="Logo" />
          </CarouselCell>
        ))}
      </Flickity>
    </>
  );
}
