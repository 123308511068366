export const months = [
  'Januari',
  'Februari',
  'Maart',
  'April',
  'Mei',
  'Juni',
  'Juli',
  'Augustus',
  'September',
  'Oktober',
  'November',
  'December',
];

export const pageTransVariants = {
  left: {
    x: 'calc(120vw)',
  },
  right: {
    x: 'calc(-100vw)',
  },
};
