import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import Moment from 'react-moment';
import 'moment/locale/nl';
import PortableText from '@sanity/block-content-to-react';

const TitleStyles = styled.div`
  @media screen and (max-width: 39.9375em) {
    grid-column: 1 / span 12;
  }
  grid-column: 1 / span 6;
  @media screen and (max-width: 39.9375em) {
    padding-left: 0;
    padding-right: 0;
  }
  @media screen and (min-width: 40em) and (max-width: 63.9375em) {
    padding-left: var(--mobileContainerMargin);
    padding-right: var(--mobileContainerMargin);
  }
`;
const EventTitleStyles = styled.div`
  @media screen and (max-width: 39.9375em) {
    grid-column: 1 / span 12;
  }
  grid-column: 1 / span 6;
  @media screen and (max-width: 39.9375em) {
    padding-left: var(--mobileContainerMargin);
    padding-right: var(--mobileContainerMargin);
  }
  @media screen and (min-width: 40em) and (max-width: 63.9375em) {
    padding-left: var(--mobileContainerMargin);
    padding-right: var(--mobileContainerMargin);
  }
`;

const DateStyles = styled.div`
  grid-column: 1 / span 12;
  @media screen and (max-width: 39.9375em) {
    order: 2;
  }
  @media screen and (min-width: 40em) and (max-width: 63.9375em) {
    grid-column: 1 / span 6;
  }
  @media screen and (min-width: 64em) {
    grid-column: 1 / span 6;
    @media screen and (max-width: 39.9375em) {
      order: 1;
    }
  }
  .dateYear {
    @media screen and (max-width: 39.9375em) {
      flex: 50%;
    }
  }
  .eventLocation {
    @media screen and (max-width: 39.9375em) {
      text-align: right;
    }
  }
  div {
    font-size: 17px;
    line-height: 27px;
  }
`;
const ArtistTitle = styled.div`
  grid-column: span 12;
  @media screen and (min-width: 40em) and (max-width: 63.9375em) {
    grid-column: 7 / span 6;
  }
  @media screen and (min-width: 64em) {
    grid-column: 7 / span 6;
  }
  display: flex;
  align-items: flex-start;
  a {
    color: unset;
    text-decoration: none;
    transition: var(--transitionFade);
    &:hover {
      color: var(--red);
    }
  }
`;
const Cta = styled.div`
  grid-column: span 12;
  text-align: center;
  margin-top: 40px;
`;

const EventList = styled.div`
  display: block;
  margin: 100px 0;
  a {
    text-decoration: none;
    color: black;
    font-family: 'regular';
    display: block;
    margin-top: -52px;
    margin-bottom: -52px;
    @media screen and (min-width: 64em) {
      margin: -52px;
    }
  }
  b {
    font-weight: 100 !important;
    font-family: 'medium';
  }
  .grid:first-child {
    margin-top: 50px;
  }
  .grid:last-child {
    border-bottom: 2px solid #d8cdc7;
  }
`;

const EventItem = styled.div`
  padding: 30px 0;
  border-top: 2px solid #d8cdc7;
  h4 {
    transition: 0.4s all ease-in-out;
  }
  &:hover {
    h4 {
      color: var(--red);
    }
  }
`;

export default function Events({ subtitle, title, events, richText }) {
  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  const dates = useRef();
  useEffect(() => {
    const datesList = dates?.current?.querySelectorAll('.date');

    datesList.forEach((date) => {
      date.innerHTML = capitalizeFirstLetter(date.innerHTML);
    });
  }, []);

  return (
    <div className="container">
      <div className="block no-padding">
        <TitleStyles>
          {subtitle ? <h6>{subtitle}</h6> : null}
          {title ? <h3>{title}</h3> : null}
          <PortableText blocks={richText} />
        </TitleStyles>
      </div>
      <EventTitleStyles>
        <EventList ref={dates}>
          {events.map((event, i) => (
            <Link key={i} to={`/event/${event.slug.current}`} className="link">
              <EventItem className="grid">
                <DateStyles>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      gap: 'var(--grid-gap)',
                    }}
                  >
                    <div className="dateYear">
                      <Moment
                        date={event.startDate}
                        className="date"
                        format="dddd  D MMMM	"
                      />
                      | <br />
                      <Moment date={event.startDate} format="HH:mm" /> -{' '}
                      <Moment date={event.endDate} format="HH:mm" />
                    </div>
                    <div className="eventLocation">
                      <b>
                        {event.location}, {event.place}
                      </b>
                    </div>
                  </div>
                </DateStyles>
                <ArtistTitle>
                  <h4>{event.name}</h4>
                </ArtistTitle>
              </EventItem>
            </Link>
          ))}
        </EventList>
        <Cta>
          <Link to="/speellijst">Zie meer</Link>
        </Cta>
      </EventTitleStyles>
    </div>
  );
}
