import React, { useRef, useState } from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import image from '../../assets/images/image.svg';

const TitleStyles = styled.div`
  grid-column: span 12;
  display: flex;
  align-items: center;
  @media screen and (min-width: 64em) {
    grid-column: 3 / span 8;
  }
`;

const TextLinkStyles = styled.div`
  img {
    position: absolute;
    width: 150%;
    @media screen and (min-width: 40em) {
      width: 200%;
    }
    @media screen and (min-width: 64em) {
      width: 560px;
    }
  }
  .textHolder {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: var(--flipcardHeight);
    width: var(--flipcardWidth);
    justify-content: center;
    z-index: 1;
    text-align: center;
    padding: 0px 0px;

    h2 {
      width: 100%;
      text-align: center;
    }
    p {
      width: 65%;
      text-align: center;
    }
  }
  a {
    position: absolute;
    text-decoration: underline;
    &:hover {
      text-decoration: none;
    }
    @media screen and (max-width: 39.9375em) {
      bottom: 2%;
    }
    @media screen and (min-width: 40em) and (max-width: 63.9375em) {
      bottom: -8%;
    }
    @media screen and (min-width: 64em) {
      bottom: 22%;
    }
  }

  .flip-card {
    background-color: rgba(255, 255, 255, 0);
    perspective: 1100px;
    position: relative;
    height: var(--flipcardHeight);
    width: var(--flipcardWidth);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    @media screen and (max-width: 39.9375em) {
      margin-left: calc(var(--mobileContainerMargin) / 2);
    }
    margin-left: unset;
  }

  .flip-card-inner {
    position: relative;
    width: inherit;
    height: inherit;
    text-align: center;
    transition: transform 0.35s;
    transform-style: preserve-3d;
  }

  .flip-card:hover .flip-card-inner {
    @media screen and (min-width: 64em) {
      transform: rotateY(180deg);
    }
  }

  .flip-card-front,
  .flip-card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }

  .flip-card-back {
    transform: rotateY(180deg);
  }
`;

export default function ImageBlock({ text, description, pageLink }) {
  const [show, setShow] = useState(0);
  const divRref = useRef(null);

  function scrollToBlock() {
    divRref.current.scrollIntoView({ behavior: 'smooth' });
  }
  return (
    <>
      <div className="block text-link">
        <TitleStyles>
          <TextLinkStyles
            onMouseOver={() =>
              window.innerWidth < 1025 ? null : setShow({ bool: true })
            }
            onMouseOut={() => setShow({ bool: false })}
          >
            <div className="flip-card">
              <img src={image} alt="Image" />
              <div className="flip-card-inner">
                {show.bool ? (
                  <div className="flip-card-back">
                    <div className="textHolder">{description}</div>
                  </div>
                ) : (
                  <div className="flip-card-front">
                    <div className="textHolder">
                      <h2 style={{ pointerEvents: 'none' }}>
                        {text?.map((text, i) => (
                          <span key={i} className={`text-color-${text.color}`}>
                            {text.name}{' '}
                          </span>
                        ))}
                      </h2>
                    </div>
                  </div>
                )}
              </div>

              <a className="pointer" onClick={scrollToBlock}>Lees meer</a>
            </div>
          </TextLinkStyles>
        </TitleStyles>
      </div>
      <div ref={divRref} />
    </>
  );
}
