import React from 'react';
import { Link } from 'gatsby';
import Moment from 'react-moment';
import 'moment/locale/nl';
import styled from 'styled-components';

export default function EventList({ filtered }) {
  const currentYear = new Date().getFullYear();

  const newFiltered = filtered.sort((a, b) => {
    const dateA = new Date(a.startDate);
    const dateB = new Date(b.startDate);
    if (isNaN(dateA.getTime()) || isNaN(dateB.getTime())) {
      return 0;
    }
    return dateA.getTime() - dateB.getTime();
  });

  const now = new Date();
  const isoString = now.toISOString();

  return (
    <>
      <>
        {filtered?.length ? (
          filtered?.map((event, i) => (
            <div
              className="event event-item-styles"
              style={
                isoString < event.startDate ? { opacity: 1 } : { opacity: 0.4 }
              }
              key={i}
            >
              <Link key={i} to={`/event/${event?.slug?.current}`}>
                <div className="text-holder">
                  <div className="left">
                    <h5>{event.name}</h5>

                    <Moment
                      date={event.startDate}
                      className="date event-list"
                      format="dddd D MMMM YYYY"
                    />
                  </div>
                  <div className="right">
                    {event?.startDate ? (
                      <Moment
                        date={event.startDate}
                        className="date event-list"
                        format="LT"
                      />
                    ) : null}
                    {event?.endDate ? (
                      <>
                        {' '}
                        -{' '}
                        <Moment
                          date={event.endDate}
                          className="date event-list"
                          format="LT"
                        />
                      </>
                    ) : null}
                    {event.location ? (
                      <div className="location">
                        {event.location}, {event.place}
                      </div>
                    ) : null}
                  </div>
                </div>
              </Link>
            </div>
          ))
        ) : (
          <div>Geen events in deze maand...</div>
        )}
      </>
    </>
  );
}
