import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import PortableText from '@sanity/block-content-to-react';
import Flickity from 'react-flickity-component';

const TitleStyles = styled.div`
  grid-column: 1 / span 12;
`;

const FlickityStyles = styled.div`
  grid-column: 1 / span 12;
  .makers-carousel {
    width: 100%;
    margin-bottom: 0;
  }
  .flickity-viewport {
    overflow: hidden;
  }
  .carousel-cell {
    position: relative;
    height: 335px;
  }
  .carousel-cell {
    height: 350px;
    margin-right: 20px;
    position: relative;

    img {
      height: 355px;
      @media screen and (min-width: 64em) {
        width: calc(100% - 20px);
      }
    }
  }
`;

const ArrowStyles = styled.div`
  grid-column: span 12;
  display: flex;
  justify-content: flex-end;
  @media screen and (min-width: 64em) {
    grid-column: span 12;
  }
`;
const ArtistCard = styled.div`
  grid-column: span 6;
  width: 100%;
  @media screen and (min-width: 64em) {
    grid-column: span 4;
  }
  position: relative;

  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;

    @media screen and (min-width: 64em) {
      width: calc(100% - 20px);
    }
    height: 100%;
    background: #9d9d9d;
    opacity: 0.6;
    transition: 0.3s all ease-in-out;
    cursor: pointer;
    pointer-events: none;
  }
  &:hover {
    &:before {
      opacity: 0;
    }
  }
  img {
    width: 100%;
    object-fit: cover;
  }
  h4 {
    position: absolute;
    color: #fff;
    z-index: 10;
    font-size: 25px;
    line-height: 26px;
    bottom: 15px;
    right: 40px;
    left: 20px;
    @media screen and (min-width: 40em) {
      font-size: 38px;
      line-height: 42px;
      bottom: 30px;
      right: 25px;
      left: 25px;
    }
  }
`;

const flickityOptions = {
  prevNextButtons: false,
  pageDots: false,
  wrapAround: true,
  autoPlay: 2500,
  cellAlign: 'left',
  pauseAutoPlayOnHover: false,
};

const CarouselCell = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  min-height: 30px;
  min-height: 60px;
  width: 100%;
  @media screen and (min-width: 40em) {
    width: 33%;
  }
`;

export default function MakersSlider({ subtitle, title, artists, richText }) {
  const [flckty, setFlckty] = useState(null);

  useEffect(() => {
    if (flckty) {
      flicktyNext();
    }
    console.log('ff');
  });
  function flicktyNext() {
    flckty.next();
  }

  return (
    <div className="wrapper background beige">
      <div className="block">
        <TitleStyles>
          {subtitle ? <h6>{subtitle}</h6> : null}
          {title ? <h2>{title}</h2> : null}
          <PortableText blocks={richText} />
        </TitleStyles>

        <FlickityStyles>
          <Flickity
            flickityRef={(c) => setFlckty(c)}
            className="carousel makers-carousel" // default ''
            elementType="div" // default 'div'
            style={{ outline: 'none' }}
            options={flickityOptions} // takes flickity options {}
            disableImagesLoaded={false} // default false
            reloadOnUpdate // default false
            static // default false
          >
            {artists?.map((artist, i) => (
              <CarouselCell className="carousel-cell">
                <ArtistCard>
                  <Link key={i} to={`/artist/${artist?.slug?.current}`}>
                    <h4>{artist?.name}</h4>
                    <img src={artist?.image?.asset?.url} alt={artist.name} />
                  </Link>
                </ArtistCard>
              </CarouselCell>
            ))}
          </Flickity>
        </FlickityStyles>
        <ArrowStyles onClick={flicktyNext}>
          <svg
            className="carousel-slide-arrow"
            alt="back"
            width="35"
            xmlns="http://www.w3.org/2000/svg"
            width="35"
            viewBox="0 0 25.988 25.483"
          >
            <g transform="translate(1 0.897)">
              <g
                data-name="Icon feather-arrow-up-right"
                transform="translate(11.869) rotate(45)"
              >
                <path
                  data-name="Path 10"
                  d="M0,16.64,16.569,0"
                  transform="translate(0 0.145)"
                  fill="none"
                  stroke="#f45e3f"
                  strokeLinejoin="round"
                  strokeWidth="2"
                />
                <path
                  data-name="Path 11"
                  d="M0,.391,16.359,0l-.383,16.407"
                  transform="translate(0.145 0)"
                  fill="none"
                  stroke="#f45e3f"
                  strokeWidth="2"
                />
              </g>
            </g>
          </svg>
        </ArrowStyles>

        {/*
          artists?.map((artist, i) => (
            <>
              <ArtistCard key={i}>
                <Link key={i} to={`/artist/${artist?.slug?.current}`}>
                  <h4>{artist?.name}</h4>
                  <img src={artist?.image?.asset?.url} alt={artist.name} />
                </Link>
              </ArtistCard>
            </>
          ))  
          */}
      </div>
    </div>
  );
}
