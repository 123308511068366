import React from 'react';

import Img from 'gatsby-image';
import TextLink from './TextLink';
import MakersSlider from './MakersSlider';
import Events from './Events';
import LogoSlider from './LogoSlider';
import Newsletter from './Newsletter';
import Calender from './Calender';
import TextColumn from './TextColumn';
import Grid from '../Grid';

export default function ContentBlocks({ blocks, events }) {
  return (
    <>
      {blocks?.map((block, i) => (
        <React.Fragment key={i}>
          {block._type === 'block' ? null : null}
          {block._type === 'blockArtist' ? (
            <MakersSlider
              subtitle={block.subtitle}
              title={block.title}
              artists={block.artist}
              richText={block.richText}
            />
          ) : null}
          {block._type === 'blockEvent' ? (
            <React.Fragment key={i}>
              <Events
                subtitle={block.subtitle}
                title={block.title}
                richText={block.richText}
                events={block.event}
              />
            </React.Fragment>
          ) : null}
          {block._type === 'blockTextColumn' ? (
            <React.Fragment key={i}>
              <TextColumn
                textLeft={block.text_left}
                textRight={block.text_right}
              />
            </React.Fragment>
          ) : null}
          {block._type === 'blockTextLink' ? (
            <Grid key={i}>
              <TextLink
                text={block.textLinks}
                description={block.description}
                pageLink={block.pageLink}
              />
            </Grid>
          ) : null}
          {block._type === 'blockLogos' ? (
            <React.Fragment key={i}>
              <LogoSlider
                key={i}
                subtitle={block.subtitle}
                title={block.title}
                richText={block.richText}
                images={block.images}
              />
            </React.Fragment>
          ) : null}
          {block._type === 'blockNewsletter' ? (
            <React.Fragment key={i}>
              <Newsletter key={i} title={block.title} />
            </React.Fragment>
          ) : null}
          {block._type === 'blockYearCalender' ? (
            <React.Fragment key={i}>
              <Calender key={i} image={block.image} events={events} />
            </React.Fragment>
          ) : null}
        </React.Fragment>
      ))}
    </>
  );
}
