import React, { useState } from 'react';
import styled from 'styled-components';
import PortableText from '@sanity/block-content-to-react';
import rizoom from '../../assets/images/rizoom.svg';
const ColumnStyles = styled.div`
  grid-column: span 12;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  img {
    width: calc(100% - 30px);
    margin-top: 15px;
    @media screen and (min-width: 64em) {
      margin-top: 0px;
      width: calc(100% - 30px);
    }
  }
  @media screen and (min-width: 64em) {
    &.left {
      grid-column: span 3;
      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        color: var(--red);
      }
    }
    &.right {
      grid-column: span 9;
    }
  }
  p {
    margin-top: 0;
  }
`;
export default function TextColumn({ textLeft, textRight }) {
  return (
    <>
      <div className="block">
        <ColumnStyles className="left">
          {textLeft ? <PortableText blocks={textLeft} /> : null}
          <div><img src={rizoom} width="296" alt="Rizoom" /></div>
        </ColumnStyles>
        <ColumnStyles className="right">
          {textRight ? <PortableText blocks={textRight} /> : null}
        </ColumnStyles>
      </div>
    </>
  );
}
