import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';

import { months } from '../../exports';
import 'moment/locale/nl';
import EventList from '../EventList';

import Grid from '../Grid';

export default function Calender({ image, events }) {
  const [activePage, setActivePage] = useState(false);
  const [filtered, setFiltered] = useState([]);
  const [monthNumber, setMonthNumber] = useState('');
  const monthsRef = useRef();
  const today = new Date();
  const filteredInitialMonth = [];
  let filteredList = [];

  function setEventsCurrentMonth(eventsList) {
    const yearNum = today.getUTCFullYear();
    const monthNumber = today.getMonth();
    eventsList.nodes.forEach((event) =>
      yearNum === parseInt(event?.startDate?.toString().split('-')[0]) &&
      monthNumber + 1 === parseInt(event?.startDate?.toString().split('-')[1])
        ? filteredInitialMonth.push(event)
        : null
    );
    setFiltered(filteredInitialMonth);
  }
  function setCurrentMonth() {
    let monthNum = today.getMonth();
    monthNum += 1;
    setMonthNumber(monthNum);
    setEventsCurrentMonth(events);
  }

  useEffect(() => {
    setFiltered(events.nodes);
    setTimeout(() => {
      setActivePage(true);
      setCurrentMonth();
    }, 10);
    setCurrentMonth();
  }, []);

  function removeActive() {
    const monthsNode = monthsRef.current.querySelectorAll('.months .month');
    monthsNode.forEach((node) => node.classList.remove('is-active'));
  }

  function setEventsCurrentMonth(eventsList) {
    const yearNum = today.getUTCFullYear();
    const monthNumber = today.getMonth();
    eventsList.nodes.forEach((event) => {
      const eventYear = parseInt(event?.startDate?.toString().split('-')[0]);
      if (yearNum === eventYear) {
        const eventMonth = parseInt(event?.startDate?.toString().split('-')[1]);
        if (monthNumber + 1 === eventMonth) {
          filteredInitialMonth.push(event);
        }
      }
    });
    setFiltered(filteredInitialMonth);
  }

  function filterEvents(month, eventsList, e) {
    removeActive();
    e.target.classList.add('is-active');
    const yearNum = today.getUTCFullYear();
    filteredList = eventsList
      .filter((event) => {
        const eventYear = parseInt(event?.startDate?.toString().split('-')[0]);
        return yearNum === eventYear;
      })
      .filter((event) => {
        const currentMonth = parseInt(
          event?.startDate?.toString().split('-')[1]
        );
        return month === currentMonth;
      });
    const sortedList = filteredList.sort((a, b) => {
      if (a.startDate > b.startDate) {
        return -1;
      }
      if (a.startDate < b.startDate) {
        return 1;
      }
      return 0;
    });
    setFiltered(sortedList);
  }

  return (
    <>
      <div className="container no-margin-top event-holder">
        <div className="months-holder">
          <div className="months" ref={monthsRef}>
            {months.map((month, i) => (
              <motion.div
                className={monthNumber - 1 === i ? 'month is-active' : 'month'}
                key={i}
                onClick={(e) => filterEvents(i + 1, events.nodes, e)}
                whileHover={{
                  transform: 'rotate(-10deg)',
                  fontFamily: 'medium',
                }}
              >
                {month}
              </motion.div>
            ))}
          </div>
        </div>
        <div className="event-container">
          <Grid>
            <div className="image-style">
              <img src={image?.asset.url} width="100%" height="auto" />
            </div>
            <div className="event-list-style">
              <EventList filtered={filtered} />
            </div>
          </Grid>
        </div>
      </div>
    </>
  );
}
