import React, { useEffect, useContext } from 'react';

import GlobalContext from '../context/GlobalContextProvider';

export default function TransformNav() {
  const [navNumber, setNavNumber, direction, checkNavPosition] = useContext(
    GlobalContext
  );
  useEffect(() => {
    checkNavPosition(navNumber);
  }, []);
  return <></>;
}
