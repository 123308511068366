import React from 'react';

export default function SEO({ title, desc }) {
  return (
    <>
      {title && (
        <>
          <meta property="og:title" content={title} />
          <title>{title}</title>
        </>
      )}

      {desc && (
        <>
          <meta name="description" content={desc} />
          <meta property="og:description" content={desc} />
        </>
      )}
    </>
  );
}
